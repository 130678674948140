<template>
    <div>
        <template v-if="sharedWith.length > 1">
            <styled-tooltip
                position="top">
                <template #content>
                    <ul>
                        <li
                            v-for="(pm, index) in sharedWith"
                            :key="index">
                            <span>{{ pm.performance_manager.name }}</span>
                        </li>
                    </ul>
                </template>
                <span
                    class="text-link"
                    href="#"
                    @click.prevent="() => false">
                    {{ sharedWith.length }}
                </span>
            </styled-tooltip>
        </template>
        <template v-else-if="sharedWith.length === 1">
            <span
                class="text-link"
                @click="$emit('open-user-settings', sharedWith[0].performance_manager)">{{ sharedWithName }}</span>
        </template>
        <template v-else-if="sharedWith.length === 0">
            Admin
        </template>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    components: {
        StyledTooltip
    },
    props: {
        item: {
            type: Array,
            required: true
        }
    },
    computed: {
        sharedWithName() {
            return this.sharedWith[0].performance_manager?.name ?? '';
        },
        sharedWith() {
            const sharedWith = this.item.filter(user => user.performance_manager !== null);
            const filteredSharedWith = [];
            if (sharedWith.length > 1) {
                // removing duplication from back-end response
                sharedWith.forEach(item => {
                    const userExists = filteredSharedWith.find(user => user.performance_manager.name === item.performance_manager.name) ?? false;
                    if (!userExists) { filteredSharedWith.push(item) }
                });
                return filteredSharedWith;
            } else {
                return sharedWith;
            }
        },
    }
};
</script>
