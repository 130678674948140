<template>
    <div class="groups-custom-table">
        <user-settings-slideout
            v-model="userSettingsOpened"
            :user="selectedUser"
            @refresh="resetFilters" />
        <create-group-modal
            v-model="createGroupModalOpen"
            :accounts="selectedItemsFiltered"
            @refresh="resetFilters" />
        <share-modal
            v-model="shareModalOpen"
            :accounts="selectedItemsFiltered"
            @refresh="resetFilters" />
        <!-- Delete Group Dialog Start -->
        <styled-dialog
            v-model="deleteGroupModalOpen"
            :width="666">
            <template #heading>
                <div class="modal-heading">
                    <h2>
                        Delete Group
                    </h2>
                    <span
                        class="close"
                        @click="deleteGroupModalOpen = false">
                        <icon
                            name="close"
                            size="20"
                            color="#8F9EA6" />
                    </span>
                </div>
            </template>
            <div class="modal">
                <div class="description mx-5 mb-4">
                    <h2>Are you sure you want to delete this group?</h2>
                    <h3>This action is permanent and cannot be undone.</h3>
                </div>
                <div class="actions text-center mx-5 mb-5">
                    <a
                        href="#"
                        @click.prevent="deleteGroupModalOpen = false">
                        Cancel
                    </a>
                    <styled-button
                        class="ml-5 small"
                        :loading="deleteGroupLoading"
                        :disabled="deleteGroupLoading"
                        @click="deleteGroup">
                        CONFIRM DELETION
                    </styled-button>
                </div>
            </div>
        </styled-dialog>
        <!-- Delete Group Dialog End -->

        <!-- Delete Account Dialog Start -->
        <styled-dialog
            v-model="deleteAccountModalOpen"
            :width="666">
            <template #heading>
                <div class="modal-heading">
                    <h2>
                        Remove Account from Group
                    </h2>
                    <span
                        class="close"
                        @click="deleteAccountModalOpen = false">
                        <icon
                            name="close"
                            size="20"
                            color="#8F9EA6" />
                    </span>
                </div>
            </template>
            <div class="modal">
                <div class="description mx-5 mb-4">
                    <h2>Are you sure you want to remove this account from the group?</h2>
                    <h3>This action will remove the account, but you can share it back at any time.</h3>
                </div>
                <div class="actions text-center mx-5 mb-5">
                    <a
                        href="#"
                        @click.prevent="deleteAccountModalOpen = false">
                        Cancel
                    </a>
                    <styled-button
                        class="ml-5 small"
                        :loading="deleteAccountLoading"
                        :disabled="deleteAccountLoading"
                        @click="deleteAccount">
                        CONFIRM REMOVAL
                    </styled-button>
                </div>
            </div>
        </styled-dialog>
        <!-- Delete Group Dialog End -->
        <styled-card
            hide-toggle
            class="mb-4">
            <template #action-buttons>
                <div v-outside-click="hideTooltip">
                    <div
                        v-if="selectedItems.length"
                        class="accounts-selected"
                        @click="accountsTooltipActive = true">
                        {{ selectedItems.length }} Account<span v-if="selectedItems.length !== 1">s</span> selected
                        <accounts-tooltip
                            v-if="accountsTooltipActive"
                            :selected-items="selectedItems"
                            :selection="true"
                            @select-item="selectItem"
                            @create="accountsTooltipActive = false; createGroupModalOpen = true;" />
                    </div>
                </div>
                <styled-tooltip
                    :max-width="220"
                    position="top">
                    <template
                        v-if="selectedItems.length === 0"
                        #content>
                        <div class="text-center">
                            Please select at least 1 account to create a group.
                        </div>
                    </template>
                    <div
                        v-if="permissionCreateAccountGroup"
                        class="create-button"
                        :class="{'disabled': selectedItems.length === 0}"
                        @click="createGroupModalOpen = true">
                        Create a Group
                    </div>
                </styled-tooltip>
                <styled-tooltip
                    :max-width="220"
                    position="top">
                    <template
                        v-if="selectedItems.length === 0"
                        #content>
                        <div class="text-center">
                            Please select at least 1 account to share with a group.
                        </div>
                    </template>
                    <div
                        class="create-button"
                        :class="{'disabled': selectedItems.length === 0}"
                        @click="shareModalOpen = true">
                        Share
                    </div>
                </styled-tooltip>
                <action-button
                    class="pr-3 ml-5"
                    icon="refresh"
                    :disabled="loadingGroups"
                    @click.prevent="resetFilters()">
                    Refresh
                </action-button>
            </template>
            <custom-table
                ref="groupsTable"
                name="groups"
                :items="groups"
                :fields="fields"
                :loading="loadingGroups"
                :pagination="pagination"
                :rows-per-page="pagination.rowsPerPage"
                :total-items="pagination.meta.totalItems"
                :notification="notification"
                :notification-type="notificationType"
                :selected-filters="selectedFilters"
                :custom-filters="customFilters"
                min-width="1200px"
                backend-selection
                backend-filtering
                custom-expand
                has-filters
                selectable
                expandable
                hide-sort
                @select-item="selectAll"
                @update-pagination="updateGroups"
                @update-filtering="updateFiltering"
                @search="onSearch">
                <template #header-slot>
                    <div
                        v-if="selectedTags.length"
                        class="filters-wrapper">
                        <div class="filter-title">
                            Filtered by:
                        </div>
                        <div class="filter-applied">
                            <template v-if="selectedTags.length">
                                <div
                                    v-for="(tag, index) in selectedTags"
                                    :key="index"
                                    class="filter-single">
                                    <span>{{ tag }}</span>
                                    <div @click="clearTag(tag)">
                                        <icon
                                            name="close"
                                            color="#8f9ea6"
                                            size="10"
                                            @click="clearTag(tag)" />
                                    </div>
                                </div>
                            </template>
                            <div
                                class="filter-clear"
                                @click="clearAllSelections">
                                Clear all
                            </div>
                        </div>
                    </div>
                </template>
                <template #expand="{ item }">
                    <table
                        v-if="item.dealers.data.length"
                        class="expandable-table">
                        <tr
                            v-for="(account, index) in item.dealers.data"
                            :key="index">
                            <td
                                width="7.8%"
                                class="selection-cell">
                                <span
                                    class="styled-checkbox-checkmark"
                                    :class="{'selected': account.selected}"
                                    @click.stop="selectItem(account, account.selected, item)" />
                            </td>
                            <td width="8%">
                                <router-link
                                    :to="{name: 'dealer', params: { dealer_id: account.id }}">
                                    {{ account.id }}
                                </router-link>
                            </td>
                            <td width="10%">
                                {{ formatDate(account.created_at.date) }}
                            </td>
                            <td width="12%">
                                {{ sharedByDate(account.groups_shared_by_info.data, item.id) }}
                            </td>
                            <td width="6.8%" />
                            <td width="21.5%">
                                <router-link
                                    :to="{name: 'dealer', params: { dealer_id: account.id }}">
                                    {{ account.name }}
                                </router-link>
                            </td>
                            <td width="13.8%">
                                <span v-if="sharedByName(account.groups_shared_by_info.data, item.id) === ''">Admin</span>
                                <span
                                    v-else
                                    class="text-link"
                                    @click="openUserSlideout(sharedByUserData(account.groups_shared_by_info.data, item.id))">
                                    {{ sharedByName(account.groups_shared_by_info.data, item.id) }}
                                </span>
                            </td>
                            <td width="13.6%">
                                <account-shared-with
                                    :item="account.groups_shared_with_info.data"
                                    @open-user-settings="openUserSlideout" />
                            </td>
                            <td
                                width="5%"
                                class="text-center">
                                <action-button
                                    v-if="permissionUnshareAccountGroup"
                                    icon="indicator-error"
                                    @click="openDeleteAccountDialog(account, item)" />
                            </td>
                        </tr>
                    </table>
                    <div
                        v-else
                        class="mx-5 pt-3 pb-3">
                        There are no accounts in this group
                    </div>
                </template>
            </custom-table>
        </styled-card>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { format } from 'date-fns';
import Icon from '@/components/globals/Icon';
import StyledCard from '@/components/globals/StyledCard.vue';
import StyledButton from '@/components/globals/StyledButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import ActionButton from '@/components/globals/ActionButton.vue';
import StyledDialog from '@/components/globals/StyledDialog.vue';
import ShareModal from '@/components/account-management/ShareModal';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import AccountsTooltip from '@/components/account-management/AccountsTooltip';
import CreateGroupModal from '@/components/account-management/CreateGroupModal';
import AccountSharedWith from '@/components/account-management/AccountSharedWith';
import UserSettingsSlideout from '@/components/account-management/UserSettingsSlideout';
import {
    PERMISSION_CREATE_ACCOUNT_GROUP,
    PERMISSION_DELETE_ACCOUNT_GROUP,
    PERMISSION_UNSHARE_ACCOUNT_GROUP,
} from '@/helpers/globals';

export default {
    components: {
        Icon,
        ShareModal,
        StyledCard,
        CustomTable,
        ActionButton,
        StyledDialog,
        StyledButton,
        StyledTooltip,
        CreateGroupModal,
        AccountSharedWith,
        AccountsTooltip,
        UserSettingsSlideout,
    },
    title: 'Groups',
    data() {
        return {
            groups: [],
            fields: [
                {
                    id: 'id',
                    header: 'ID',
                    value: '',
                    align: 'left',
                    width: '8%',
                    type: 'empty',
                },
                {
                    id: 'created_at',
                    header: 'Date Created',
                    value: 'created_at.date',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    type: 'date',
                },
                {
                    id: 'shared_at',
                    header: 'Date Shared',
                    value: '',
                    align: 'left',
                    width: '10%',
                    sortable: false,
                    type: 'empty',
                },
                {
                    id: 'group_type',
                    header: 'Group type',
                    value: 'group_type',
                    align: 'left',
                    width: '9%',
                    type: 'component',
                    filter: 'multiple',
                    placeholder: 'All',
                    component: () => import('@/components/account-management/GroupType.vue'),
                },
                {
                    id: 'name',
                    header: 'Group / Account',
                    value: 'name',
                    align: 'left',
                    width: '14%',
                    sortable: true,
                    filter: 'search',
                    type: 'component',
                    component: () => import('@/components/account-management/GroupName.vue'),
                    callback: () => {
                        this.resetFilters();
                    },
                },
                {
                    id: 'accounts',
                    header: 'Accounts',
                    value: '',
                    align: 'left',
                    width: '8%',
                    type: 'component',
                    component: () => import('@/components/account-management/GroupAccounts'),
                },
                {
                    id: 'shared_by_info.shared_by.name',
                    header: 'Shared By',
                    value: 'shared_by_info.shared_by.name',
                    align: 'left',
                    width: '14%',
                    sortable: false,
                    filter: 'search',
                    type: 'component',
                    component: () => import('@/components/account-management/UserLink.vue'),
                    callback: (item) => {
                        this.openUserSlideout(item.created_by_user);
                    },
                    props: {
                        type: 'created_by'
                    }
                },
                {
                    id: 'performance_manager.name',
                    header: 'Shared With',
                    value: 'performance_manager.name',
                    align: 'left',
                    width: '14%',
                    sortable: false,
                    filter: 'search',
                    type: 'component',
                    component: () => import('@/components/account-management/UserLink.vue'),
                    callback: (item) => {
                        this.openUserSlideout(item.performance_manager);
                    },
                },
                {
                    id: 'trash',
                    header: '',
                    value: '',
                    align: 'center',
                    width: '5%',
                    type: 'component',
                    component: () => import('@/components/users/UserActionButton.vue'),
                    callback: (value) => {
                        this.openDeleteGroupDialog(value);
                    },
                    props: {
                        icon: 'indicator-error',
                        showIcon: true
                    }
                },
            ],
            selectedFilters: {},
            customFilters: [{ 'group_type': ['Personal', 'Organization'] }],
            filters: null,
            selectedTags: [],
            pagination: {
                rowsPerPage: 25,
                sortBy: 'created_at',
                descending: true,
                page: 1,
                meta: {
                    totalItems: 1,
                    current_page: 1,
                    last_page: 1,
                    to: 1,
                    from: 1,
                }
            },
            notification: '',
            notificationType: '',
            raw: [],
            search: '',
            selectedItems: [],
            createGroupModalOpen: false,
            shareModalOpen: false,
            loadingGroups: true,
            deleteGroupModalOpen: false,
            deleteGroupId: null,
            deleteGroupLoading: false,
            deleteAccountModalOpen: false,
            deleteAccountId: null,
            deleteAccountLoading: false,
            selectedUser: {},
            userSettingsOpened: false,
            accountsTooltipActive: true,
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
            selectedGroups: (state) => state.agency.selectedGroups,
            agencyGroups: (state) => state.agency.groups,
            groupsLoading: (state) => state.agency.groupsLoading,
        }),
        ...mapGetters([
            'userIsAdmin',
        ]),
        permissionCreateAccountGroup() {
            return this.$store.getters.userHasPermission(PERMISSION_CREATE_ACCOUNT_GROUP);
        },
        permissionDeleteAccountGroup() {
            return this.$store.getters.userHasPermission(PERMISSION_DELETE_ACCOUNT_GROUP);
        },
        permissionUnshareAccountGroup() {
            return this.$store.getters.userHasPermission(PERMISSION_UNSHARE_ACCOUNT_GROUP);
        },
        selectedItemsFiltered() {
            const uniqueIds = new Set();
            const accountsIds = this.selectedItems.map(account => { return account.id });
            accountsIds.forEach(id => { uniqueIds.add(id) });
            return Array.from(uniqueIds);
        },
        selectedGroupsIds() {
            return this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
        }
    },
    watch: {
        agencyGroups() {
            this.getGroups();
        },
        selectedGroups() {
            this.getGroups();
        }
    },
    activated() {
        this.getGroups();
    },
    created() {
        if (!this.permissionDeleteAccountGroup) {
            this.fields.at(-1).props.showIcon = false;
        }
    },
    methods: {
        clearAllSelections() {
            this.selectedFilters = {};
            this.filters = null;
            this.populateTags();
            this.getGroups();
        },
        clearTag(tag) {
            const currentTag = this.fields.find(field => field.header == tag);
            this.selectedFilters[currentTag.id] = [];
            this.populateTags();
            this.updateFiltering('', currentTag.id);
        },
        populateTags() {
            const tags = new Set();
            for (const filter in this.selectedFilters) {
                const currentFilter = this.fields.find(field => field.id == filter);
                if (this.selectedFilters[filter].length) {
                    tags.add(currentFilter.header);
                } else {
                    tags.delete(currentFilter.header);
                }
            }
            this.selectedTags = Array.from(tags);
        },
        updateFiltering(value, filter) {
            this.selectedFilters[filter] = value;
            const filters = [];
            Object.keys(this.selectedFilters).forEach(key => {
                if (this.selectedFilters[key] instanceof Array) {
                    if (this.selectedFilters[key].length) {
                        const arrayFilter = {
                            column: key,
                            operator: 'IN',
                            value: this.selectedFilters[key]
                        };
                        filters.push(arrayFilter);
                    }
                } else if (this.selectedFilters[key]) {
                    // additional filtering in accounts table because we have only 1 search input for both!
                    if (filter === 'performance_manager.name') {
                        const dealersFilter = {
                            column: 'dealers.groups_shared_with_info.performance_manager.name',
                            operator: 'like',
                            value: '%'+this.selectedFilters[key]+'%'
                        };
                        filters.push(dealersFilter);
                    }
                    if (filter === 'name') {
                        const dealersFilter = {
                            column: 'dealers.name',
                            operator: 'like',
                            value: '%'+this.selectedFilters[key]+'%'
                        };
                        filters.push(dealersFilter);
                    }
                    const stringFilter = {
                        column: key,
                        operator: 'like',
                        value: '%'+this.selectedFilters[key]+'%'
                    };
                    filters.push(stringFilter);
                }
            });
            this.filters = filters;
            this.populateTags();
            this.getGroups();
        },
        openUserSlideout(user) {
            this.userSettingsOpened = true;
            this.selectedUser = user;
        },
        sharedByName(data, id) {
            const groupInfo = data.find(item => item.group_id === id);
            return groupInfo.shared_by?.name ?? '';
        },
        sharedByUserData(data, id) {
            const groupInfo = data.find(item => item.group_id === id);
            return groupInfo.shared_by;
        },
        sharedByDate(data, id) {
            const groupInfo = data.find(item => item.group_id === id);
            return this.formatDate(groupInfo.shared_date.date);
        },
        openDeleteGroupDialog(group) {
            this.deleteGroupModalOpen = true;
            this.deleteGroupId = group.id;
        },
        async deleteGroup() {
            try {
                this.deleteGroupLoading = true;
                await this.$apiRepository.deleteGroup(this.deleteGroupId);
                await this.resetFilters();
                this.deleteGroupModalOpen = false;
                this.$store.dispatch('getGroups', this.currentAgency.id);
                this.$flash('The group has been successfully deleted!', 'green');
            } catch (error) {
                console.log(error);
                this.$flash('Error deleting this group!', 'red');
            } finally {
                this.deleteGroupLoading = false;
            }
        },
        openDeleteAccountDialog(account, group) {
            this.deleteAccountModalOpen = true;
            this.deleteAccountData = {
                accountId: account.id,
                groupId: group.id
            };
        },
        async deleteAccount() {
            try {
                this.deleteAccountLoading = true;
                const data = {
                    dealer_ids: [this.deleteAccountData.accountId],
                    group_ids: [this.deleteAccountData.groupId],
                };
                await this.$apiRepository.deleteAccount(data);
                await this.resetFilters();
                this.deleteAccountModalOpen = false;
                this.$flash('The account has been successfully removed!', 'green');
            } catch (error) {
                console.log(error);
                this.$flash('Error deleting this account!', 'red');
            } finally {
                this.deleteAccountLoading = false;
            }
        },
        formatDate(date) {
            return format(date, 'MM/DD/YYYY');
        },
        onSearch: debounce(async function(search) {
            this.pagination.page = 1;
            if (search) {
                this.search = search;
            } else {
                this.search = '';
            }
            await this.getGroups();
        }, 500),
        selectAll(item, currentSelection) {
            this.groups.forEach(group => {
                if (item.id === group.id) {
                    group.dealers.data.forEach(account => {
                        if (!currentSelection && account.selected) {
                            account.selected = false;
                            const index = this.selectedItems.findIndex(selectedItem =>{
                                return selectedItem.parentGroupId === group.id && selectedItem.id === account.id;
                            });
                            if (index > -1) { this.selectedItems.splice(index, 1) }
                        }
                        if (currentSelection && !account.selected) {
                            account.selected = true;
                            const newSelection = {
                                id: account.id,
                                name: account.name,
                                parentGroup: group,
                                parentGroupId: group.id
                            };
                            this.selectedItems.push(newSelection);
                        }
                    });
                    if (currentSelection) {
                        group.selected = true;
                    } else {
                        group.selected = false;
                    }
                }
            });
        },
        hideTooltip() {
            this.accountsTooltipActive = false;
        },
        selectItem(item, currentSelection, currentGroup) {
            const currentItem = {
                id: item.id,
                name: item.name,
                parentGroup: currentGroup,
                parentGroupId: currentGroup.id
            };
            if (currentSelection) {
                const index = this.selectedItems.findIndex(selectedItem =>{
                    return selectedItem.parentGroupId === currentGroup.id && selectedItem.id === item.id;
                });
                if (index > -1) { this.selectedItems.splice(index, 1) }
            } else {
                this.selectedItems.push(currentItem);
            }
            this.groups.forEach(group => {
                if (currentGroup.id === group.id) {
                    group.dealers.data.forEach(account => {
                    if (account.id === item.id) {
                            account.selected = !currentSelection;
                        }
                    });
                    const currentGroupSelections = [];
                    this.selectedItems.forEach(item => {
                        if (item.parentGroupId === currentGroup.id) {
                            currentGroupSelections.push(item);
                        }
                    });
                    if (currentGroupSelections.length && currentGroupSelections.length === group.dealers.data.length) {
                        group.selected = true;
                    } else {
                        group.selected = false;
                    }
                }
            });
        },
        async updateGroups(pagination) {
            Object.assign(this.pagination, pagination);
            await this.getGroups();
        },
        async getGroups() {
            try {
                this.loadingGroups = true;
                const selectedGroups = this.selectedGroupsIds.length ? this.selectedGroupsIds : null;
                const response = await this.$apiRepository.getAccountGroupsQuery(this.pagination, this.search, this.filters, this.currentAgency.id, selectedGroups);
                // check selections for accounts inside each group
                const groupsResponse = response.data.data.map((group) => {
                    group.dealers.data.forEach(account => {
                        const currentAccount = {
                            id: account.id,
                            parentGroupId: group.id
                        };
                        const selectionExist = this.selectedItems.some(item =>{
                            return JSON.stringify(currentAccount) === JSON.stringify(item);
                        });
                        account.selected = selectionExist ? true : false;
                    });
                    return group;
                });
                // check selections for groups based on accounts selection
                groupsResponse.forEach(group => {
                    const groupSelections = [];
                    this.selectedItems.forEach(item => {
                        if (item.parentGroupId === group.id) {
                            groupSelections.push(item);
                        }
                    });
                    if (groupSelections.length === group.dealers.data.length && groupSelections.length) {
                        group.selected = true;
                    } else {
                        group.selected = false;
                    }
                });
                this.groups = groupsResponse;
                this.raw = response.data;
                const newPagination = { ...this.pagination };
                newPagination.meta.totalItems = this.raw.meta.total;
                newPagination.meta.current_page = this.raw.meta.current_page;
                newPagination.meta.last_page = this.raw.meta.last_page;
                newPagination.meta.to = this.raw.meta.to;
                newPagination.meta.from = this.raw.meta.from;
                this.pagination = newPagination;
                this.loadingGroups = false;
            } catch (error) {
                this.$flash('Error retrieving groups', 'red');
                this.loadingGroups = false;
                this.notification = error + '!';
                this.notificationType = 'error';
            }
        },
        resetFilters() {
            this.groups = [];
            this.pagination = {
                rowsPerPage: 25,
                sortBy: 'created_at',
                descending: true,
                page: 1,
                meta: {
                    totalItems: 1,
                    current_page: 1,
                    last_page: 1,
                    to: 1,
                    from: 1,
                }
            };
            this.selectedItems = [];
            if (this.search) {
                this.$refs.groupsTable.clearSearch();
            } else {
                this.getGroups();
            }
        },
    }
};
</script>

<style lang="scss">
.groups-custom-table {
    .styled-card .styled-card-header {
        margin: 10px 0;
    }
    tbody .table-row {
        background-color: #E2E7E9 !important;
        border-top: 5px solid white;
        color: #2B3249;
        min-height: 70px;
    }
    .expandable-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 13px;
        tr {
            background-color: white;
            border-bottom: 1px solid #C7CED3;
            &:nth-of-type(odd) {
                background-color: #F9FAFA;
            }
            &:last-of-type {
                border-bottom: none;
            }
        }
        td {
            padding: 10px 15px;
        }
    }
    .styled-card {
        position: relative;
        overflow: visible;
    }
    .accounts-selected {
        color: $blue-bg;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;
        position: relative;
        cursor: pointer;
    }
    .create-button {
        padding: 5px 15px;
        background: $blue-btn;
        color: $white;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        text-transform: uppercase;
        &.disabled {
            background: #B8C2C7;
            cursor: default;
            pointer-events: none;
        }
    }
    .selection-cell {
        text-align: right;
    }
    .styled-checkbox-checkmark {
        position: relative;
        display: inline-block;
        margin-top: 5px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        border: 2px solid;
        border-color: $gray;
        background-color: $white;
        transform-origin: center center;
        transform: scale(var(--checkmark-scale));
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 6px;
            height: 11px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        &:hover {
            background-color: $alabaster-dark;
        }
        &.selected {
            background-color: $blue-btn;
            border-color: $blue-btn;
            &::after {
                display: block;
            }
        }
    }
    .header-cell .filter {
        color: #6D797F !important;
        [data-lastpass-icon-root] {
            display: none;
        }
    }
    .header-cell.active .filter{
        color: #050c11 !important;
    }
    .filters-wrapper {
        margin-top: -20px;
        display: inline-block;
        margin-left: 30px;
    }
    .filter-title{
        color: #00A4EB;
        margin-bottom: 5px;
    }
    .filter-applied{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
    }
    .filter-single{
        display: flex;
        align-items: center;
        background: #D7F0FB;
        border-radius: 25px;
        padding: 5px 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        span{
            margin-right: 15px;
        }
        div{
            display: flex;
            align-items: center;
            margin-top:2px;
            cursor: pointer;
        }
    }
    .filter-clear{
        color: #00A4EB;
        text-decoration: underline;
        cursor: pointer;
    }
}
.modal-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.modal {
    .description {
        color: #6D797F;
        h2 {
            color: #6D797F;
            font-size: 23px;
        }
        h3 {
            color: #6D797F;
            font-size: 18px;
            margin-bottom: 100px;
        }
    }
    .close {
        cursor: pointer;
    }
    button.small {
        height: 40px;
    }
}
</style>
