<template>
    <styled-interface hide-toolbar>
        <div class="am-tabs">
            <div class="am-tab">
                <router-link
                    :to="{name: 'account-management', params: { tab: 'accounts' }}">
                    Accounts
                </router-link>
            </div>
            <div class="am-tab">
                <router-link
                    :to="{name: 'account-management', params: { tab: 'groups' }}">
                    Groups
                </router-link>
            </div>
            <div class="am-tab">
                <router-link
                    :to="{name: 'account-management', params: { tab: 'users' }}">
                    Users
                </router-link>
            </div>
        </div>
        <keep-alive>
            <AllAccounts v-if="currentTab === 'accounts'" />
            <AllGroups v-if="currentTab === 'groups'" />
            <AllUsers v-if="currentTab === 'users'" />
        </keep-alive>
    </styled-interface>
</template>

<script>
import StyledInterface from '@/components/globals/StyledInterface';
import AllAccounts from '@/components/dealers/Dealers';
import AllGroups from '@/components/account-management/GroupsTab';
import AllUsers from '@/components/users/UsersCustomTable';

export default {
    components: {
        StyledInterface,
        AllAccounts,
        AllGroups,
        AllUsers
    },
    computed: {
        currentTab() {
            return this.$route.params.tab;
        }
    }
};
</script>

<style lang="scss">
.icon-button{
    padding: 5px 15px;
    background: $blue-btn;
    color: $white;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    right: -15px;
    top: -50px;
    span {
        margin-left: 10px;
    }
    svg {
        transform: scale(1.3) translateX(-3px);
    }
}
</style>

<style lang="scss" scoped>
.am-tabs {
    display: flex;
    align-items: flex-end;
}
.am-tab{
    background: white;
    margin-left: 15px;
    position: relative;
    a{
        position: relative;
        z-index: 2;
        border: 1px solid $gray-light;
        border-bottom: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: white;
        text-transform: none;
        padding: 0 30px;
        padding-bottom: 5px;
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        height: 60px;
        width: 110px;
        color: $gray-dark;
        &.router-link-active {
            box-shadow: 0 0 10px 3px rgba(0,0,0,0.1);
            border: 1px solid transparent;
            position: relative;
            font-weight: 600;
            color: $blue-btn;
            height: 75px;
            font-weight: bold;
            &:before{
                display: none;
            }
        }
        &:before{
            content: '';
            position: absolute;
            top: 100%;
            left: -1px;
            right: -1px;
            height: 1px;
            z-index: 1;
            box-shadow: 0 0 10px 3px rgba(0,0,0,0.1);
        }
        &:after{
            content: '';
            position: absolute;
            top: 100%;
            left: -1px;
            right: -1px;
            height: 30px;
            z-index: 2;
            background: white;
        }
    }
}
</style>
